import React, { Component } from 'react';
import logo from './123banklogo.png';
import chatbutton from './chatbutton.svg';
import chatwindow from './chatwindow.png';
import loggedInBackground from './loggedInBackground.png';
import './App.scss';
import './App.css';
import awsConfig from './config.json';
import {CognitoAuth} from 'amazon-cognito-auth-js';


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
        showChatWindow: false,
    };
    this.toggleChatWindowVisibility = this.toggleChatWindowVisibility.bind(this);
  }

  toggleChatWindowVisibility() {
    const currentState = this.state.showChatWindow;
    this.setState({ showChatWindow: !currentState });
  };

  renderLogin() {
    var auth = new CognitoAuth(awsConfig.Auth);
    auth.useCodeGrantFlow();
    const loginUrl = 'https://idp.123-bank.com/oauth2/authorize?response_type=code&client_id=1c3vm1v9091bbqbcupc8invn84&redirect_uri=https://www.123-bank.com/callback&state=12345&scope=openid';
    //const loginUrl = 'https://idp.123-bank.com/oauth2/authorize?response_type=code&client_id=1c3vm1v9091bbqbcupc8invn84&redirect_uri=http://localhost:3000/callback&state=12345&scope=openid';
    console.log("Hello");
    console.log(awsConfig);
    return (
      <>
      <section className="hero is-primary">
	      <div className="hero-body">
              <div>
                <figure className="image container is-256x256">
                  <img src={logo} alt=""></img>
                  </figure>
                <div className="container has-text-centered">
                
                <h1 className="title">Backoffice</h1>
                <p className="subtitle">Open Credit</p>
                <a className="button" href={loginUrl}>Login</a>
                </div>
              </div>
        </div>
      </section>
      <footer className="footer">
      <div className="content has-text-centered">
        <p>
          <strong>OpenCredit</strong> by <a href="https://collenda.com">Collenda</a> - <a href="https://api-doc.123-bank.com/">API</a>
        </p>
      </div>
    </footer>
    </>
    );
  }

  renderLoggedInPage() {
    return (
      <div>
        <img src={loggedInBackground} alt="Background" className="loggedInBackgroundImage"/>
        <div id="chatbutton" onClick={this.toggleChatWindowVisibility}>
          <img src={chatbutton} alt="ChatButton"/>
        </div>
        <div id="chatwindow" className={this.state.showChatWindow ? 'show' : 'hidden'}>
          <img src={chatwindow} alt="ChatWindow"/>
        </div>
      </div>
    );  
  }

  render() {
    if(window.location.href.indexOf("callback") > 0) 
      return this.renderLoggedInPage();
    else 
      return this.renderLogin();
  }
}

export default App;
